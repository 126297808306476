import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';

import * as Sentry from '@sentry/react';
import { render } from 'react-dom';

import config from '@shared/config';

import { performanceMonitoringToggle } from '@ManagerPortal/featureToggles';

import { fetchConfiguration } from './shared/api/configuration';

import './index.scss';

import store from './ManagerPortal/redux/store';
import Analytics from './ManagerPortal/utils/analytics';
import { updateConfig } from './shared/config/utils';
import AppProvider from './shared/containers/AppProvider';
import ErrorReporting from './shared/utils/errorreporting';

if (__DEV__) {
  window.store = store;
}

const initialise = () => {
  ErrorReporting.init();
  Analytics.startLocationTracking();
  if (performanceMonitoringToggle) {
    // eslint-disable-next-line no-restricted-globals
    const environment = location.host;

    Sentry.init({
      dsn: config.sentryDsn,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
      ],
      tracesSampleRate: 1.0,
      profilesSampleRate: 1.0,
      environment,
      tracePropagationTargets: [
        'https://local.web-test.quinyx.com/',
        /^https:\/\/web-test.quinyx.com\/extapi/,
      ],
    });
  }
  render(<AppProvider />, document.getElementById('app-container'));
};

fetchConfiguration()
  .then(({ body }) => {
    updateConfig(body);
    initialise();
  })
  .catch(() => initialise());
